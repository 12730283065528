<template>
  <div class="main">
    <!-- <about-us></about-us> -->
    <melon-header></melon-header>
    <!-- <banner></banner> -->
    <!-- <case></case> -->
    <main-content></main-content>
    <about-us></about-us>
    <melon-footer></melon-footer>
  </div>
</template>

<script>
import MelonHeader from '@/components/layout/MelonHeader.vue';
import MelonFooter from '@/components/layout/MelonFooter.vue';
import AboutUs from '@/views/home/components/AboutUs.vue';
import MainContent from '@/views/case/components/MainContent/index.vue';

export default {
  components: {
    MelonHeader,
    MelonFooter,
    AboutUs,
    MainContent,
  },
  data() {
    return {
    };
  },

  methods: {
    openQrcodeBox() {
      this.qrcodeBoxVisible = true;
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.main{
  background: #F3F6FA;
}
</style>
